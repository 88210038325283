import React from 'react'

import { MarketingDashboardPage } from '../../components'

class Marketing extends React.Component {
  render() {
    return <MarketingDashboardPage />
  }
}

export default Marketing
